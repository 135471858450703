import React from "react"

import { addDays } from "date-fns"

import { TextLink } from "@kiwicom/orbit-components"
import { Calendar } from "@kiwicom/orbit-components/icons"

import PersonCalendar from "components/person-calendar"

import { Audience } from "types/audience"

const CalendarSummary = () => {
  const dateFrom = new Date()
  const dateTo = addDays(new Date(), 7)

  return (
    <PersonCalendar
      audience={Audience.driver}
      showDateRangeSelector={false}
      noEventsMessage="Nothing scheduled in the next 7 days"
      titleIcon={<Calendar />}
      titleText="Next 7 Days"
      actions={
        <TextLink type="secondary" href="calendar/">
          Full Calendar
        </TextLink>
      }
      initialDateRange={{ start: dateFrom, end: dateTo }}
    />
  )
}

export default CalendarSummary
