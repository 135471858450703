import React from "react"

import { Stack, TextLink } from "@kiwicom/orbit-components/"
import {
  Bus,
  City,
  InformationCircle,
  NewWindow,
  Passenger,
} from "@kiwicom/orbit-components/icons"

import CalendarSummary from "components/calendar-summary"
import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import { DriveLayout } from "components/layout-custom"

const Page = () => {
  return (
    <DriveLayout title="Driver Hub">
      <Container>
        <Stack spacing="XLarge">
          <CalendarSummary />
          <EmberCard
            icon={<InformationCircle />}
            title="Driving and Route Information"
          >
            <EmberCardSection>
              <Stack direction="column">
                <TextLink href="diversions/">Common Diversions</TextLink>
                <TextLink href="tachograph/">
                  Tachograph and Drivers' Hours
                </TextLink>
                <TextLink href="emergency/">Emergency Procedure</TextLink>
                <TextLink href="pre-booked-stops/">
                  Pre-Booked Only Stops
                </TextLink>
              </Stack>
            </EmberCardSection>
            <EmberCardSection title="Forms and Live Information">
              <Stack direction="column">
                <TextLink
                  href="https://goember.notion.site/d6dc86bcf7094ed3849765f2bdf1682f?v=3dfe314d24a3406a8f3ccc1ca0bd8937"
                  external
                  iconRight={<NewWindow />}
                >
                  Current Road Incidents and Diversions
                </TextLink>
                <TextLink href="lost-property/">Lost Property</TextLink>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard icon={<Passenger />} title="Team and HR">
            <EmberCardSection>
              <Stack direction="column">
                <TextLink
                  href="https://goember.notion.site/58a46779d68c42629bf376b6caca136e?v=be7cef6a7cb04bfcaf8e3b758b4a70f4"
                  external
                  iconRight={<NewWindow />}
                >
                  Meet the Team Page
                </TextLink>
                <TextLink href="policies/">Policies and Guides</TextLink>
                <TextLink href="company-updates/">Company Updates</TextLink>
              </Stack>
            </EmberCardSection>
            <EmberCardSection title="Forms">
              <Stack direction="column">
                <TextLink href="rota-adjustments/">
                  Shift Swaps and Extra Hours
                </TextLink>
                <TextLink
                  external
                  href="https://notionforms.io/forms/feedback-and-questions"
                  iconRight={<NewWindow />}
                >
                  Feedback Form
                </TextLink>
                <TextLink
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfxy20EwA-F24wfL7-xCeM5dVpoPTVxC5FBnxU500efQZEC6A/viewform?usp=sf_link"
                  external
                  iconRight={<NewWindow />}
                >
                  Recommend a Friend
                </TextLink>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard icon={<City />} title="Charging Hubs and Offices">
            <EmberCardSection>
              <Stack direction="column">
                <TextLink href="sites/dundee-greenmarket/">
                  Greenmarket Car Park Office, Dundee
                </TextLink>
                <TextLink href="sites/dundee-msip/">
                  Michelin Scotland Innovation Parc, Dundee
                </TextLink>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard icon={<Bus />} title="Guides and Information">
            <EmberCardSection title="General">
              <Stack direction="column">
                <TextLink href="guides/general/sharps-kit/">
                  Using the Sharps Kit
                </TextLink>
                <TextLink href="guides/general/concession-card-reader/">
                  Concession Card Reader
                </TextLink>
                <TextLink href="guides/general/alcolock-failure/">
                  Alcolock Failure Process
                </TextLink>
              </Stack>
            </EmberCardSection>
            <EmberCardSection title="Yutong TCe12 Coach">
              <Stack direction="column">
                <TextLink href="guides/yutong-tce12/cctv/">
                  Onboard CCTV
                </TextLink>
                <TextLink href="guides/yutong-tce12/dashboard-lights/">
                  Dashboard Lights
                </TextLink>
                <TextLink href="guides/yutong-tce12/wheelchair-lift/">
                  Helping a Wheelchair User to Board
                </TextLink>
                <TextLink href="guides/yutong-tce12/manually-releasing-charger-gun/">
                  Manually Releasing the Charger Gun (Video)
                </TextLink>
                <TextLink href="guides/yutong-tce12/unlocking-exterior-doors/">
                  Unlocking Exterior Doors (Video)
                </TextLink>
                <TextLink href="guides/yutong-tce12/conserving-power/">
                  Conserving Power
                </TextLink>
                <TextLink href="guides/yutong-tce12/demisting-windscreen/">
                  Demisting the Windscreen
                </TextLink>
              </Stack>
            </EmberCardSection>
          </EmberCard>
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export default Page
